// Copyright 2024 Diffblue Limited. All Rights Reserved.
// Unpublished proprietary source code.
// Use is governed by https://docs.diffblue.com/licenses/eula

import { useEffect, useState } from "react";
import VerificationPage from "../verify/VerificationPage";
import { Auth0Error, Auth0UserProfile, WebAuth } from "auth0-js";
import { fetchUserInfo } from "../service/Auth0Service";
import { post, postTelemetryEvent } from "../service/HttpService";
import { parseAuth0UserDetails, toFormPostRequestBody } from "./SocialUserDetails";
import { parseAuth0State } from "../email/UserDetails";

/**
 * Page to display when verifying an SSO login attempt.
 *
 * This will be fired from an Auth0 callback. The user information provided by SSO is
 * stored in Auth0 initially and is not returned on callback, so we have to fetch it first.
 *
 * @returns JSX element of page.
 */
function VerifySocialLogin(props: { auth0: WebAuth }) {
  const [auth0Error, setAuth0Error] = useState<Auth0Error>();
  const [userInfo, setUserInfo] = useState<Auth0UserProfile>();

  // For social login, Auth0 passes the state parameter in the url hash instead...
  const hash = window.location.hash;
  const state = hash.split("&state=")[1];
  const { key, distinctId, ijVersion, job, company } = parseAuth0State(state);

  useEffect(() => {
    postTelemetryEvent("/on-sso-redirect", distinctId).then(() => {
      fetchUserInfo(props.auth0, distinctId, setAuth0Error, onUserInfoFetched);
    });
    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VerificationPage
      status={userInfo ? "OK" : auth0Error ? "ERROR" : "PENDING"}
      ceKey={key}
      distinctId={distinctId}
    />
  );

  async function onUserInfoFetched(info: Auth0UserProfile) {
    if (key !== "") {
      const res = await post(
        "/ce-email-form/store-user-details",
        toFormPostRequestBody(parseAuth0UserDetails(info, key, distinctId, job, company, ijVersion))
      );
      if (res.ok) {
        setUserInfo(info);
        return;
      }
    }
    setAuth0Error({ error: "Something went wrong updaing the users license" });
  }
}

export default VerifySocialLogin;
