// Copyright 2024 Diffblue Limited. All Rights Reserved.
// Unpublished proprietary source code.
// Use is governed by https://docs.diffblue.com/licenses/eula

import "./SocialLoginButton.css";

/** Type to allow different social login providers. */
type SocialLogin = "Google" | "Github" | "Gitlab";

interface Properties {
  /** True if the button should be disabled. */
  isDisabled: boolean;
  /** Name of social login provider */
  socialLogin: SocialLogin;
  /** Icon of the social provider to display */
  iconSrc: string;
  /** Listener for onclick on the button  */
  onClick: () => void;
}

/**
 * Component for a generic SSO login button that can be used for all social login providers.
 *
 * @param props {@link Properties}
 * @returns A JSX element of login button
 */
function SocialLoginButton(props: Properties) {
  return (
    <div className="py-1">
      <button
        disabled={props.isDisabled}
        className="btn border rounded-5 px-2 d-inline-flex align-items-center sso-button"
        onClick={props.onClick}
      >
        <span className="px-3">
          <img src={props.iconSrc} width={24} height={24} alt={props.socialLogin + " logo"} />
        </span>
        Continue with {props.socialLogin}
      </button>
    </div>
  );
}

export default SocialLoginButton;
